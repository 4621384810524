import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const containers = [
    DashboardComponent,
    DashboardHomeComponent,
];

export * from './dashboard/dashboard.component';
export * from './dashboard-home/dashboard-home.component';
