/* tslint:disable: ordered-imports*/
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';

/* Components */
import * as navigationComponents from './components';

/* Containers */
import * as navigationContainers from './containers';

/* Layouts */
import * as appCommonLayouts from './layouts';

/* Guards */
import * as navigationGuards from './guards';

/* Services */
import * as navigationServices from './services';
import { AlertsService } from '@modules/alerts/services';

import { DashboardHomeComponent } from '@modules/dashboard/containers';
import { SubNavRightComponent } from '@modules/navigation/components';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';


import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';

export const MY_MOMENT_FORMATS = {
    parseInput: 'YYYY/MM/DD HH:mm:ss',
    fullPickerInput: 'YYYY/MM/DD HH:mm:ss',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  };
  
@NgModule({
    imports: [CommonModule, 
        RouterModule, 
        ReactiveFormsModule,
        FormsModule,
        AppCommonModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,],
    declarations: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    exports: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    providers: [DashboardHomeComponent, SubNavRightComponent, { provide: 'window', useValue: window }],
})
export class NavigationModule {
    static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            providers: [
                ...navigationServices.services,
                ...navigationGuards.guards,
                AlertsService,
            ],
        };
    }
}
