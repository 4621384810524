import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CopyToClipboard, CopyToClipboardOptions } from '@common/models';
import copyToClipboard from 'copy-to-clipboard';
import { map, Observable } from 'rxjs';
import { environment, environmentTesting, environmentDev, environmentLocal, environmentDemo } from '../../../environments/environment';

@Injectable()
export class UtilityService {
    _url: string = environment.apiUrl;
    _window: Window;
    parse: JSON['parse'];
    stringify: JSON['stringify'];
    localStorage: Storage;
    _copyToClipboard: CopyToClipboard;

    constructor(private http: HttpClient) {
        this._window = window;
        this.parse = JSON.parse;
        this.stringify = JSON.stringify;
        this.localStorage = localStorage;
        this._copyToClipboard = copyToClipboard;
    }

    get window(): Window {
        return this._window;
    }
    get version$(): Observable<string> {
        return this.http.get('/assets/version', { responseType: 'text' });
    }

    httpPost(controllerName: string, actionName: string, t: any): Observable<any> {

        if (localStorage.getItem("environment") == "testing") {
            this._url = environmentTesting.apiUrl;
        }
        else if (localStorage.getItem("environment") == "dev") {
            this._url = environmentDev.apiUrl;
        }
        else if (localStorage.getItem("environment") == "demo") {
            this._url = environmentDemo.apiUrl;
        }

        let url = this._url;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'POST',
            })


        };

        url = url + controllerName + '/' + actionName;

        return this.http.post(url,
            t, httpOptions
        )


    }

    getStoredObject<T>(objectName: string): T | undefined {
        const objectString = this.localStorage.getItem(objectName);
        if (!objectString) {
            return undefined;
        }
        return this.parse(objectString) as T;
    }

    storeObject(objectName: string, objectToStore: {}): void {
        this.localStorage.setItem(objectName, this.stringify(objectToStore));
    }

    copyToClipboard(text: string, options?: CopyToClipboardOptions) {
        this._copyToClipboard(text, options);
    }
}



// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { CopyToClipboard, CopyToClipboardOptions } from '@common/models';
// import copyToClipboard from 'copy-to-clipboard';
// import { Observable } from 'rxjs';

// @Injectable()
// export class UtilityService {
//     _window: Window;
//     parse: JSON['parse'];
//     stringify: JSON['stringify'];
//     localStorage: Storage;
//     _copyToClipboard: CopyToClipboard;

//     constructor(private http: HttpClient) {
//         this._window = window;
//         this.parse = JSON.parse;
//         this.stringify = JSON.stringify;
//         this.localStorage = localStorage;
//         this._copyToClipboard = copyToClipboard;
//     }

//     get window(): Window {
//         return this._window;
//     }
//     get version$(): Observable<string> {
//         return this.http.get('/assets/version', { responseType: 'text' });
//     }

//     getStoredObject<T>(objectName: string): T | undefined {
//         const objectString = this.localStorage.getItem(objectName);
//         if (!objectString) {
//             return undefined;
//         }
//         return this.parse(objectString) as T;
//     }

//     storeObject(objectName: string, objectToStore: {}): void {
//         this.localStorage.setItem(objectName, this.stringify(objectToStore));
//     }

//     copyToClipboard(text: string, options?: CopyToClipboardOptions) {
//         this._copyToClipboard(text, options);
//     }
// }


