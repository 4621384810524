import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'sbpro-dashboard-sub-head',
    templateUrl: './dashboard-sub-head.component.html',
    styleUrls: ['dashboard-sub-head.component.scss'],
})
export class DashboardSubHeadComponent implements OnInit {
    @Input() icon!: string;
    @Input() title!: string;
    @Input() description!: string;
    @Input() breadcrumbs = false;
    @Input() simplified = false;
    @Input() light = false;
    @Input() showDateRange!: false;
    @Input() showSearch!: false;

    darkClasses = ['page-header-dark', 'bg-gradient-primary-to-secondary'];
    lightClasses = ['page-header-light', 'bg-white', 'shadow'];

    constructor(private route: Router) {}
    ngOnInit() {}

    async loadModal(modalOption: any){
        localStorage.setItem("page", modalOption);
        
        if(modalOption == "device") {
            this.route.navigateByUrl("/dashboard/metering/device");
        }
        if(modalOption == "meteringSection") {
            this.route.navigateByUrl("/dashboard/metering/meteringSection");
        }
        if(modalOption == "meteringPoint") {
            this.route.navigateByUrl("/dashboard/metering/meteringPoint");
        }
        if(modalOption == "user") {
            this.route.navigateByUrl("/dashboard/metering/user");
        }
        
        
    }
}
