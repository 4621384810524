import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        HttpClientModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
        NgxGoogleAnalyticsModule.forRoot('G-VPPEHKT6LC'),
        GoogleTagManagerModule.forRoot({
            id: 'G-VPPEHKT6LC',
        }),
    ],
    providers: [{provide: 'googleTagManagerId',  useValue: 'G-VPPEHKT6LC'}],
    bootstrap: [AppComponent],
})
export class AppModule {}
