/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faColumns,
    faDollarSign,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faLongArrowAltRight,
    faMousePointer,
    faPercentage,
    faPlus,
    faPlusCircle,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faUser,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faColumns,
    faDollarSign,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faLongArrowAltRight,
    faMousePointer,
    faPercentage,
    faPlus,
    faPlusCircle,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faUser,
};
