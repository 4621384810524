import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    TemplateRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '@common/services/utility.service';
import { SBRouteData, SideNavItem, SideNavItems } from '@modules/navigation/models';
import { NavigationService, SideNavService } from '@modules/navigation/services';
import objectHash from 'object-hash';
import { Subscription } from 'rxjs';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SubNavRightComponent } from '@modules/navigation/components';
import { ApiRequestService } from '@common/services/api-request.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

import { ToastService } from '@common/services/toast.service';
import { AlertsService } from '@modules/alerts/services';

@Component({
    selector: 'sbpro-top-nav-alerts',
    templateUrl: './top-nav-alerts.component.html',
    styleUrls: ['top-nav-alerts.component.scss'],
})
export class TopNavAlertsComponent implements OnInit {
    @Input() placement = 'bottom-end';
    @ViewChild('notesDetails') notesDetails!: TemplateRef<unknown>;
    @ViewChild('actionDetails') actionDetails!: TemplateRef<unknown>;

    
    dropdownClasses: string[] = [];
    alertsList: any = [];
    wordRegex = /(?=[A-Z_])/;
    wordRegexReplace = /_/g
    glow = "";
    notesList = [];
    actionList = [];

    pageCons = 1;
    pageSizeCons = 5;
    pageCont = 1;
    pageSizeCont = 4;
    collectionSize = 0;

    constructor(
        private modalService: NgbModal,
        private ngxService: NgxUiLoaderService,
        public alertsService: AlertsService,
        private toastService: ToastService,
        private router: Router,
        private apiRequest: ApiRequestService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.getAlerts();
        this.glow = "";
    }

    getAlerts() {
        // this.ngxService.start();
        // setTimeout(() => {
        //     this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        // }, 2000);

        let body = {
            "payload": {
                "userID": parseInt(localStorage.getItem("userID") as string),
                "alertsType": "all"
            }
        }

        this.apiRequest.apiRequest("utilities/alerts/", "alerts.json", body)
            .subscribe(res => {
                if (res.success) {

                    for(var i in res.data.alerts){
                        if(res.data.alerts[i].records && res.data.alerts[i].records.length > 0){
                            this.glow = "glow";
                            this.changeDetectorRef.detectChanges();
                        }
                    }
                    
                    this.alertsList = res.data.alerts;

                    this.changeDetectorRef.detectChanges();

                }
               
            });
    }

    async loadPage(page: any, records: any){

        if(page == "offlineDevices"){
            localStorage.setItem("active", "alerts");
            this.router.navigateByUrl("/dashboard/notifications/alerts");
        }
        if(page == "notes"){
            localStorage.setItem("active", "alerts");
            this.viewNoteDetails(records)
        }
        if(page == "actions"){
            localStorage.setItem("active", "alerts");
            this.viewActionDetails(records)
        }
        if(page == "accountVerification"){
            localStorage.setItem("active", "dashboard");
            this.router.navigateByUrl("/dashboard/account/profile");
        }
        
    }

    async viewNoteDetails(records: any) {
        this.ngxService.start();
        setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 2000);

        this.notesList = records;

        this.collectionSize = records.length;

        this.modalService.open(this.notesDetails, { size: 'lg' }).result.then(
            (result) => {

            },
            (reason) => { }
        );

        this.changeDetectorRef.detectChanges();
        
    }

    async viewActionDetails(records: any) {
        this.ngxService.start();
        setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 2000);

        this.actionList = records;

        this.collectionSize = records.length;

        this.modalService.open(this.actionDetails, { size: 'lg' }).result.then(
            (result) => {

            },
            (reason) => { }
        );

        this.changeDetectorRef.detectChanges();
        
    }

    viewDevice(deviceID: any){
        localStorage.setItem("deviceID",deviceID);
        //this.router.navigateByUrl("/dashboard/device");
        window.location.assign("/dashboard/device")

    }

}
