
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map, Observable, tap, of } from 'rxjs';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
    SocialAuthService,
    GoogleLoginProvider,
    FacebookLoginProvider,
    SocialUser,
} from '@abacritt/angularx-social-login';

@Injectable()

export class AuthGuardService implements CanActivate {
    constructor(private router: Router,
        private socialAuthService: SocialAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.socialAuthService.authState.pipe(
            map((socialUser: SocialUser) => !!socialUser),
            tap((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.router.navigate(['/auth/login-social']);
                }
            })
        );
    }

}

