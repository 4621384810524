import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiRequestService } from '@common/services/api-request.service';
import { NavigationService, SideNavService } from '@modules/navigation/services';


@Component({
    selector: 'sb-layout-auth',
    templateUrl: './layout-auth.component.html',
    styleUrls: ['layout-auth.component.scss'],
})
export class LayoutAuthComponent implements OnInit {
    images: any = []; //[944, 1011].map((n) => `https://picsum.photos/id/${n}/900/500`);
    showNavigationArrows = false;
    showNavigationIndicators = false;
    createAccountBtn = 'yes';

    //smart view host names
    hostname = ["localhost", "testing-dot-smart-view-utilities-355419.ey.r.appspot.com", "dev-dot-smart-view-utilities-355419.ey.r.appspot.com", "ums.smartviewtechnology.co.za"]

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: NgbModal,
        public navigationService: NavigationService,
        private apiRequest: ApiRequestService,
        private router: Router, private http: HttpClient,) { }

    ngOnInit() {

        if(this.navigationService._currentURL$.value.indexOf("login") > -1 && this.hostname.indexOf(window.location.hostname) < 0){
            this.loadOrganisation();
        }
        
        this.images.push("/assets/img/slider/industrial.png")
        this.images.push("/assets/img/slider/residential.png")
        this.images.push("/assets/img/slider/retail.png")

    }

    async loadOrganisation() {

        let devicesBody = {
            "payload": {
                "domain": "https://"+window.location.hostname
            }
        }

        this.apiRequest.apiRequest("users/organisation/", "organisation.json", devicesBody)
            .subscribe(res => {
                console.log(res);
                if (res.success) {
                   if(res.data.organisationName.indexOf("Smart-View") < 0){
                        this.createAccountBtn = 'no';
                        if(res.data.organisationBackgroundImages.length > 0){
                            this.images = res.data.organisationBackgroundImages;
                            console.log("images")
                        }
                   }
                }
                this.changeDetectorRef.detectChanges();

            },
                (error: any) => {
                    const defaultOptions = {
                        autohide: true,
                        delay: 10000,
                    };
                    localStorage.removeItem("deviceID");
                    console.log(error);
                    let message = "";
                    if(error.error.data){
                        for (var i = 0; i < error.error.data.length; i++) {
                            message += error.error.data[i] + "\n";
                        }
                    }
                   
                });


    }
}
