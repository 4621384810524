import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import $ from 'jquery';
import { ToastMessageData, ToastMessageOptions } from '@common/models';
import { ToastService } from '@common/services/toast.service';
import { ApiRequestService } from '@common/services/api-request.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { NavigationService } from '@modules/navigation/services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { config } from 'process';

@Component({
    selector: 'sbpro-nav-right',
    templateUrl: './sub-nav-right.component.html',
    styleUrls: ['sub-nav-right.component.scss'],
})
export class SubNavRightComponent implements OnInit {
    @Input() icon!: string;
    @Input() title!: string;
    @Input() description!: string;
    @Input() breadcrumbs = false;
    @Input() simplified = false;
    @Input() light = false;
    @Input() showDateRange!: false;
    @Input() showSearch!: false;
    @Input() deviceVersion!: string;
    @Input() deviceType!: string;
    @Input() hasConfig!: string;


    @ViewChild('deviceDelete') deviceDelete!: TemplateRef<unknown>;
    @ViewChild('deviceDeleteConfirm') deviceDeleteConfirm!: TemplateRef<unknown>;

    @ViewChild('meteringSectionDelete') meteringSectionDelete!: TemplateRef<unknown>;
    @ViewChild('meteringSectionDeleteConfirm') meteringSectionDeleteConfirm!: TemplateRef<unknown>;

    @ViewChild('meteringPointDelete') meteringPointDelete!: TemplateRef<unknown>;
    @ViewChild('meteringPointDeleteConfirm') meteringPointDeleteConfirm!: TemplateRef<unknown>;

    @ViewChild('captureReadingModal') captureReadingModal!: TemplateRef<unknown>;
    @ViewChild('captureElectricityModal') captureElectricityModal!: TemplateRef<unknown>;

    darkClasses = ['page-header-dark', 'bg-gradient-primary-to-secondary'];
    lightClasses = ['page-header-light', 'bg-white', 'shadow'];

    activeLink = "";
    activeLevel = "";
    urlEnvironment = "";
    deviceID = "";
    errorMessage = "";
    meteringSection = "";
    meteringPoint = "";
    pmLevel = "";
    readingType = "Reading Date Captured";


    deviceManagement = "viewer";
    meteringFarmManagement = "viewer";
    meteringSectionManagement = "viewer";
    meteringPointManagement = "viewer";
    notificationManagement = "viewer";

    actualReadingForm = this.fb.group({
        meterReading: ['', [Validators.required]],
        meterReadingDateTime: ['', [Validators.required]],
        meterReadingImageUrl: ['', ],
    });


    electricityReadingForm = this.fb.group({
        pC_Tot: ['', [Validators.required]],
        apPower_Tot: ['', [Validators.required]],
        tou_1: ['', [Validators.required]],
        tou_2: ['', [Validators.required]],
        tou_3: ['', [Validators.required]],
        tou_4: ['', [Validators.required]],
        meterReadingDateTime: ['', [Validators.required]],
        meterReadingImageUrl: ['', ],
    });

    hasError_startReading = false;
    hasError_closeReading = false;

    endDate!: Date;
    startDate!: Date;
    startDateFilter = new Date();
    today = new Date();
    minEndDate = {};
    maxDate = {};
    minDate = {};
    todayMax = "";

    meterType = "";

    constructor(
        private route: Router, private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private fb: FormBuilder,
        public navigationService: NavigationService,
        private apiRequest: ApiRequestService, private toastService: ToastService,
        private ngxService: NgxUiLoaderService,
        private http: HttpClient) { }

    ngOnInit() {
        this.today.setSeconds(this.today.getSeconds() + 30);
        this.todayMax = this.today.toISOString();//this.today.getFullYear() + '-' + month + '-' + day+"T"+this.today.getHours()+":"+this.today.getMinutes()+":"+this.today.getSeconds();

        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        this.meteringFarmManagement = permissions.meteringFarmManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;
        this.deviceManagement = permissions.deviceManagement;
        this.notificationManagement = permissions.notificationManagement;

        this.meterType = localStorage.getItem("meterType") as string;
        this.pmLevel = localStorage.getItem("pmLevel") as string;

        this.activeLevel = "";
        this.changeDetectorRef.detectChanges();
        this.activeLink = localStorage.getItem("activeLink") as string;
        this.activeLevel = localStorage.getItem("activeLevel") as string;
        this.deviceID = localStorage.getItem("deviceID") as string;
        this.title = this.activeLevel;
        console.log(this.activeLevel);
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit() {
        this.meteringSection = localStorage.getItem("meteringSection") as string;
        this.meteringPoint = localStorage.getItem("meteringPoint") as string;
        this.activeLink = localStorage.getItem("activeLink") as string;
        this.activeLevel = localStorage.getItem("activeLevel") as string;
        this.deviceID = localStorage.getItem("deviceID") as string;
        if (!localStorage.getItem("deviceType")) {
            localStorage.setItem("deviceType", "");
        }
        this.deviceType = localStorage.getItem("deviceType") as string;
        this.deviceType = this.deviceType.toLowerCase();

        this.changeDetectorRef.detectChanges();

        setTimeout(() => {
            this.deviceType = localStorage.getItem("deviceType") as string;
            this.deviceVersion = localStorage.getItem("deviceVersion") as string;
            this.activeLevel = localStorage.getItem("activeLevel") as string;
            this.hasConfig = localStorage.getItem("hasConfig") as string;

            if(this.deviceType.toLowerCase() == "targetdatapoint"){
                this.readingType = "Apply Reading From";
                this.today.setFullYear(this.today.getFullYear() + 5);
                this.todayMax = this.today.toISOString();//this.today.getFullYear() + '-' + month + '-' + day+"T"+this.today.getHours()+":"+this.today.getMinutes()+":"+this.today.getSeconds();
            }

            this.changeDetectorRef.detectChanges();
        }, 3000)

        setTimeout(() => {
            this.deviceType = localStorage.getItem("deviceType") as string;
            this.changeDetectorRef.detectChanges();
        }, 10000)

    }



    open(content: TemplateRef<unknown>, modalOptions: NgbModalOptions = {}) {
        this.modalService.open(content, modalOptions).result.then(
            (result) => {
                console.log(`Closed with: ${result}`);
            },
            (reason) => {
                console.log(`Dismissed ${this._getDismissReason(reason)}`);
            }
        );
    }

    _getDismissReason(reason: unknown): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    async loadPageImportData() {
        let pageOption = localStorage.getItem("activeLevel");
        if (this.deviceManagement != "viewer") {
            this.route.navigateByUrl("/dashboard/metering/importData");
        }
    }


    async loadPageAdd(pageOption: any) {
        localStorage.setItem("page", pageOption);

        if (pageOption == "device") {
            this.route.navigateByUrl("/dashboard/metering/device");
        }
        if (pageOption == "meteringSection") {
            this.route.navigateByUrl("/dashboard/metering/meteringSection");
        }
        if (pageOption == "meteringPoint") {
            this.route.navigateByUrl("/dashboard/metering/meteringPoint");
        }
        if (pageOption == "user") {
            this.route.navigateByUrl("/dashboard/metering/user");
        }


    }
    

    async loadPageEdit() {
        localStorage.removeItem("latitude")
        localStorage.removeItem("longitude")
        
        localStorage.setItem("menuIsReset", "no");
        localStorage.setItem("meterReplace", "no");
        let pageOption = localStorage.getItem("activeLevel");
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };
        let activeLevel = "";

        if (pageOption == "device" && localStorage.getItem("deviceID") && localStorage.getItem("deviceID") != "" && this.deviceManagement != "viewer") {
            this.route.navigateByUrl("/dashboard/metering");

            localStorage.setItem("deviceAction", "update_device");
            let deviceType: any = localStorage.getItem("deviceType");
            localStorage.setItem("meterTypes", "")
            if(deviceType.indexOf("e460") > -1 || deviceType.indexOf("e650") > -1) deviceType = "landis";

            setTimeout(() => {
                this.route.navigateByUrl("/dashboard/metering/" + deviceType);
            }, 500)
            //window.location.assign("/dashboard/metering/"+deviceType);
        }
        else if ((pageOption == "meteringSection" || pageOption == "modems") && localStorage.getItem("meteringSectionID") && localStorage.getItem("meteringSectionID") != "" && this.meteringSectionManagement != "viewer" && this.meteringSectionManagement != "no-access") {
            localStorage.setItem("meteringSectionAction", "update_metering_section");
            this.route.navigateByUrl("/dashboard/metering");

            setTimeout(() => {
                this.route.navigateByUrl("/dashboard/metering/meteringSection");
            }, 500)
            //window.location.assign("/dashboard/metering/meteringSection");
        }
        else if (pageOption == "meteringPoint" && localStorage.getItem("meteringPointID") && localStorage.getItem("meteringPointID") != "" && this.meteringPointManagement != "viewer" && this.meteringPointManagement != "no-access") {
            localStorage.setItem("meteringPointAction", "update_metering_point");
            this.route.navigateByUrl("/dashboard/metering");

            setTimeout(() => {
                this.route.navigateByUrl("/dashboard/metering/meteringPoint");
            }, 500)
            // window.location.assign("/dashboard/metering/meteringPoint");

        }
        else if (this.meteringSectionManagement == "viewer" || this.meteringSectionManagement == "no-access") {
            if (pageOption == "device") activeLevel = "Device " + localStorage.getItem("deviceName") as string;
            if (pageOption == "meteringSection") activeLevel = "Metering Section " + localStorage.getItem("meteringSection") as string;
            if (pageOption == "meteringPoint") activeLevel = "Metering Point " + localStorage.getItem("meteringPoint") as string;
            this.toastService.show("Edit Warning", "You do not have permissions to edit \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else if (this.meteringPointManagement == "viewer" || this.meteringPointManagement == "no-access") {
            if (pageOption == "device") activeLevel = "Device " + localStorage.getItem("deviceName") as string;
            if (pageOption == "meteringSection") activeLevel = "Metering Section " + localStorage.getItem("meteringSection") as string;
            if (pageOption == "meteringPoint") activeLevel = "Metering Point " + localStorage.getItem("meteringPoint") as string;
            this.toastService.show("Edit Warning", "You do not have permissions to edit \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else {
            this.toastService.show("Edit Warning", "Please select Metering Section or Metering Point or a Device to edit \n", { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }

    }

    

    async loadPageReplace() {
        localStorage.setItem("menuIsReset", "no");
        localStorage.setItem("meterReplace", "yes");
        let pageOption = localStorage.getItem("activeLevel");
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };
        let activeLevel = "";

        if (pageOption == "device" && localStorage.getItem("deviceID") && localStorage.getItem("deviceID") != "" && this.deviceManagement != "viewer") {
            this.route.navigateByUrl("/dashboard/metering");

            localStorage.setItem("deviceAction", "update_device");
            localStorage.setItem("meterTypes", "")
            setTimeout(() => {
                this.route.navigateByUrl("/dashboard/metering/deviceSelect");
            }, 500)
            //window.location.assign("/dashboard/metering/"+deviceType);
        }
        else {
            this.toastService.show("Replace Warning", "Please select Device to replace \n", { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }

    }
    


    async loadPageUsers() {
        let pageOption = localStorage.getItem("activeLevel");

        this.route.navigateByUrl("/dashboard/metering/users");
    }

    async loadPageNotifications() {

        localStorage.setItem("notificationAction", "add_notification");

        this.route.navigateByUrl("/dashboard/notifications/notifications");
        //window.location.assign("/dashboard/metering/notifications");

    }

    async startReadingUpdate() {
        this.ngxService.start();
        setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 2000);
        let pageOption = localStorage.getItem("startReadingUpdate");

        this.route.navigateByUrl("/dashboard/metering/startReadingUpdate");
    }

    async configForm(deviceType: any, deviceVersion: any) {
        
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        let pageOption = "";

        if(deviceType.toLowerCase() == 'visiopulse' && deviceVersion == '2'){
            pageOption = "firmwareSettings";
        }
        else if(localStorage.getItem("meterType") == 'electricity'){
            pageOption = "readingSettings";
        }
        else{
            this.toastService.show("Config Settings", "This device does not have any configurations \n", { headerClasses: 'bg-warning text-light', ...defaultOptions });
            return false;
        }
        this.ngxService.start();
        setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 2000);
        localStorage.setItem("configoption", pageOption);

        this.route.navigateByUrl("/dashboard/metering/config");
    }

    async loadPageReporting() {
        let pageOption = localStorage.getItem("reporting");

        this.route.navigateByUrl("/dashboard/reporting/report");
    }



    async deleteConfirm() {
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        let activeLevel = "";

        this.meteringSection = localStorage.getItem("meteringSection") as string;
        this.meteringPoint = localStorage.getItem("meteringPoint") as string;

        this.activeLevel = localStorage.getItem("activeLevel") as string;
        this.changeDetectorRef.detectChanges();

        if (this.activeLevel == "meteringSection" && this.meteringSectionManagement == "admin" && localStorage.getItem("meteringSectionID") != "") {
            // this.modalService.open(this.meteringSectionDeleteConfirm).result.then(
            //     (result) => {
            //         if (result === 'meteringSection') {
            //             this.deleteMeteringSection()
            //         }
            //     },
            //     (reason) => { }
            // );
            activeLevel = "Metering Section " + localStorage.getItem("meteringSection") as string;
            this.toastService.show("Delete Warning", "You cannot delete \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else if (this.activeLevel == "meteringSection" && this.meteringSectionManagement != "admin") {
            activeLevel = "Metering Section " + localStorage.getItem("meteringSection") as string;
            this.toastService.show("Delete Warning", "You do not have permissions to delete \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else if (this.activeLevel == "meteringPoint" && this.meteringPointManagement == "admin" && localStorage.getItem("meteringPointID") != "") {
            // this.modalService.open(this.meteringPointDeleteConfirm).result.then(
            //     (result) => {
            //         if (result === 'meteringPoint') {
            //             this.deleteMeteringPoint()
            //         }
            //     },
            //     (reason) => { }
            // );
            activeLevel = "Metering Point " + localStorage.getItem("meteringPoint") as string;
            this.toastService.show("Delete Warning", "You cannot delete \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else if (this.activeLevel == "meteringPoint" && this.meteringPointManagement != "admin") {
            activeLevel = "Metering Point " + localStorage.getItem("meteringPoint") as string;
            this.toastService.show("Delete Warning", "You do not have permissions to delete \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else if (this.activeLevel == "device" && this.deviceManagement == "admin" && localStorage.getItem("deviceID") != "") {
            this.modalService.open(this.deviceDeleteConfirm).result.then(
                (result) => {
                    if (result === 'device') {
                        this.deleteDevice()
                    }
                },
                (reason) => { }
            );
        }
        else if (this.activeLevel == "device" && this.deviceManagement != "admin") {
            activeLevel = "Device " + localStorage.getItem("deviceName") as string;
            this.toastService.show("Delete Warning", "You do not have permissions to delete \n" + activeLevel, { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else {
            this.toastService.show("Delete Warning", "Please select Metering Section or \nMetering Point or \n a Device to delete \n", { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }

    }

    async deleteDevice() {
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };


        let deviceID = localStorage.getItem("deviceID");
        let body = {
            "payload": {
                "deviceID": deviceID
            }
        }
        this.modalService.dismissAll();

        this.apiRequest.apiRequest("utilities/delete_device/", "delete_device.json", body)
            .subscribe(res => {
                if (res.success) {
                    localStorage.setItem("active", "users");
                    this.toastService.show(res.data.deviceName, res.message, { headerClasses: 'bg-success text-light', ...defaultOptions });
                    localStorage.removeItem("deviceID");
                    localStorage.setItem("activeLevel", "meteringFarm");
                    this.router.navigateByUrl("/dashboard/metering");
                    localStorage.setItem("reload", "yes");
                    //location.assign("/dashboard");
                    setTimeout(() =>{
                        localStorage.setItem("active", "dashboard");
                        localStorage.setItem("menuIsReset", "no");
                        this.router.navigateByUrl("/dashboard");
                    },500)
                }
                else {
                    this.toastService.show(res.data.deviceName, res.message, { headerClasses: 'bg-danger text-light', ...defaultOptions });
                }
            },
                (error: any) => {

                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.toastService.show("Delete Device", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });

                });
    }

    async deleteMeteringSection() {
        let meteringSectionID = localStorage.getItem("meteringSectionID");
        let body = {
            "payload": {
                "meteringSectionID": parseInt(localStorage.getItem("meteringSectionID") as string)
            }
        }
        this.modalService.dismissAll();
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        this.apiRequest.apiRequest("metering/delete_metering_section/", "delete_metering_section.json", body)
            .subscribe(res => {
                if (res.success) {
                    localStorage.setItem("active", "users");
                    this.toastService.show("Delete Metering Section", res.message, { headerClasses: 'bg-success text-light', ...defaultOptions });
                    localStorage.removeItem("meteringSectionID");
                    localStorage.removeItem("meteringSection");
                    localStorage.setItem("activeLevel", "meteringFarm");
                    this.router.navigateByUrl("/dashboard/metering");
                    localStorage.setItem("reload", "yes");
                    //location.assign("/dashboard");
                    setTimeout(() =>{
                        localStorage.setItem("active", "dashboard");
                        localStorage.setItem("menuIsReset", "no");
                        this.router.navigateByUrl("/dashboard");
                    },500)
                    //location.assign("/dashboard");
                }
                else {
                    this.toastService.show("Delete Metering Section", res.message, { headerClasses: 'bg-danger text-light' , ...defaultOptions});
                }
            },
                (error: any) => {
                  
                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.errorMessage = message;
                    //this.toastService.show("Delete Device", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });
                    this.modalService.open(this.meteringSectionDelete).result.then(
                        (result) => {
                            if (result === 'DASHBOARD') {
                            }
                        },
                        (reason) => { }
                    );

                });
    }


    async deleteMeteringPoint() {
        let meteringPointID = localStorage.getItem("meteringPointID");
        let body = {
            "payload": {
                "meteringPointID": parseInt(localStorage.getItem("meteringPointID") as string)
            }
        }
        this.modalService.dismissAll();

        this.apiRequest.apiRequest("metering/delete_metering_point/", "delete_metering_point.json", body)
            .subscribe(res => {
                if (res.success) {
                    this.toastService.show("Delete Metering Point", res.message, { headerClasses: 'bg-success text-light' });
                    localStorage.removeItem("meteringPointID");
                    localStorage.removeItem("meteringPointAction");
                    localStorage.setItem("activeLevel", "meteringSection");
                    localStorage.setItem("reload", "yes");
                    this.route.navigateByUrl("/dashboard");
                    //location.assign("/dashboard");
                }
                else {
                    this.toastService.show("Delete Metering Point", res.message, { headerClasses: 'bg-danger text-light' });
                }
            },
                (error: any) => {
                    const defaultOptions = {
                        autohide: true,
                        delay: 5000,
                    };

                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.errorMessage = message;
                    //this.toastService.show("Delete Device", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });
                    this.modalService.open(this.meteringPointDelete).result.then(
                        (result) => {

                        },
                        (reason) => { }
                    );

                });
    }



    vaidateInput(fieldName: any, minVal: any, minLength: any, maxLength: any) {
        let allOk = true;
        let inputVal = $("#" + fieldName).val() as string;
        let inputValue = parseInt($("#" + fieldName).val() as string);
    

        if (inputVal.length < minLength) {
            allOk = false;
        }

        if (inputVal.length > maxLength) {
            allOk = false
        }

        if (inputValue < minVal) {
            allOk = false
        }

    
        if (!allOk) {
            
            $("#" + fieldName).css("border","1px solid red");
            if(fieldName == "meterReading"){
                this.hasError_startReading = true;
            }
            if(fieldName == "closingReading"){
                this.hasError_closeReading = true;
            }

            return false;
           
        }
        else {
            
            $("#" + fieldName).css("border", "1px solid #c5ccd6");
            if(fieldName == "meterReading"){
                this.hasError_startReading = false;
            }
            if(fieldName == "closingReading"){
                this.hasError_closeReading = false;
            }
            
    
        }
        this.changeDetectorRef.detectChanges();
    }

    validateInput(fieldName: any, type: any, minLength: any, maxLength: any) {
        let allOk = true;
        let inputVal = $("#" + fieldName).val() as string;

        if(fieldName == "meterReading"){
            var re = new RegExp("^[0-9,.]+$");
            if (!re.test(inputVal)) {
             allOk = false
            }
        }
        

        if (!allOk) {
            $("#" + fieldName).css("border","1px solid red");
            return false;
        }
        else {
            $("#" + fieldName).css("border", "1px solid #c5ccd6");
        }
    }
    
    
    captureReading(){
        if(this.meterType == 'electricity'){
            this.modalService.open(this.captureElectricityModal).result.then(
                (result) => {
                    if (result === 'submit') {
                        this.submitReading()
                    }
                },
                (reason) => { }
            );

            if(localStorage.getItem("lastReadings")){
                let lastReadings: any = JSON.parse(localStorage.getItem("lastReadings") as string);
                this.electricityReadingForm.patchValue({
                    "pC_Tot": lastReadings.pC_Tot,
                    "apPower_Tot": lastReadings.apPower_Tot,
                    "tou_1": lastReadings.tou_1,
                    "tou_2": lastReadings.tou_2,
                    "tou_3": lastReadings.tou_3,
                    "tou_4": lastReadings.tou_4,
    
                })
            }
            
        }else{
            this.modalService.open(this.captureReadingModal).result.then(
                (result) => {
                    if (result === 'submit') {
                        this.submitReading()
                    }
                },
                (reason) => { }
            );
        }

        
    }

    async submitReading() {
        let body: any = { "payload": {...this.actualReadingForm.value } };

        if(this.meterType == 'electricity'){
            body = { "payload": {...this.electricityReadingForm.value } };
        }
        body.payload.deviceID =  this.deviceID
        
        var newDate = new Date(body.payload.meterReadingDateTime);
        newDate.setSeconds(0);
        body.payload.meterReadingDateTime = newDate.toLocaleDateString('zh-Hans-CN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
        body.payload.meterReadingDateTime = body.payload.meterReadingDateTime.replace(' ',"T");

        this.modalService.dismissAll();

        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        this.apiRequest.apiRequest("billing/capture_actual_reading/", "capture_actual_reading.json", body)
            .subscribe(res => {
                if (res.success) {
                    this.toastService.show("Actual Reading", res.message, { headerClasses: 'bg-success text-light', ...defaultOptions });
                    //this.route.navigateByUrl("/dashboard");
                    location.assign("/dashboard");
                }
                else {
                    this.toastService.show("Actual Reading", res.message, { headerClasses: 'bg-danger text-light', ...defaultOptions });
                }
            },
                (error: any) => {

                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.errorMessage = message;
                    this.toastService.show("Actual Reading", message, { headerClasses: 'bg-danger text-light', ...defaultOptions });


                });
    }

}
