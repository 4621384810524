import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'sbpro-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    activeTab: any = "dashboard";

    constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef) {
        router.events.subscribe((val) => {
            // see also 
            this.activeTab = localStorage.getItem("active");
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
        
    }
}
