import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        //redirectTo: '/auth/login',
        loadChildren: () =>
            import('@modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('@modules/dashboard/dashboard-routing.module').then(
                (m) => m.DashboardRoutingModule
            ),
    },
    {
        path: 'account',
        loadChildren: () =>
            import('@modules/account/account-routing.module').then((m) => m.AccountRoutingModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    },
    {
        path: 'metering',
        loadChildren: () =>
            import('@modules/metering/metering-routing.module').then((m) => m.MeteringRoutingModule),
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('@modules/notifications/notifications-routing.module').then((m) => m.NotificationsRoutingModule),
    },
    {
        path: 'reporting',
        loadChildren: () =>
            import('@modules/reporting/reporting-routing.module').then((m) => m.ReportingRoutingModule),
    },
    {
        path: 'diagnostics',
        loadChildren: () =>
            import('@modules/diagnostics/diagnostics-routing.module').then((m) => m.DiagnosticsRoutingModule),
    },
    {
        path: 'whitelabeled',
        loadChildren: () =>
            import('@modules/whitelabeled/whitelabeled-routing.module').then((m) => m.WhitelabeledRoutingModule),
    },
    {
        path: 'shop',
        loadChildren: () =>
            import('@modules/shop/shop-routing.module').then((m) => m.ShopRoutingModule),
    },
    {
        path: 'tariff',
        loadChildren: () =>
            import('@modules/tariff/tariff-routing.module').then((m) => m.TariffRoutingModule),
    },
    {
        path: 'integration',
        loadChildren: () =>
            import('@modules/integration/integration-routing.module').then((m) => m.IntegrationRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('@common/app-common-routing.module').then(
                (m) => m.AppCommonRoutingModule
            ),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('@modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('@modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
