import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    TemplateRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '@common/services/utility.service';
import { SBRouteData, SideNavItem, SideNavItems } from '@modules/navigation/models';
import { NavigationService, SideNavService } from '@modules/navigation/services';
import objectHash from 'object-hash';
import { Subscription } from 'rxjs';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SubNavRightComponent } from '@modules/navigation/components';
import { ApiRequestService } from '@common/services/api-request.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

import { ToastService } from '@common/services/toast.service';


@Component({
    selector: 'sbpro-side-nav-item',
    templateUrl: './side-nav-item-pro.component.html',
    styleUrls: ['side-nav-item-pro.component.scss'],
})
export class SideNavItemProComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('collapsibleSection') collapsibleSection!: ElementRef<HTMLDivElement>;
    @ViewChild('noDevice') noDevice!: TemplateRef<unknown>;
    @ViewChild('noMeteringSections') noMeteringSections!: TemplateRef<unknown>;
    @ViewChild('noMeteringPoints') noMeteringPoints!: TemplateRef<unknown>;


    @Input() sideNavItem!: SideNavItem;
    @Input() hierarchy: string[] = [];
    @Input() reloadNav!: string;

    isActive = false;
    collapsed!: boolean;
    routeData!: SBRouteData;
    navElement!: HTMLDivElement;
    id!: string;
    hierarchyExtension!: string[];
    subscription: Subscription = new Subscription();
    afterViewInit = false;
    activeLevel = "";
    deviceVersion = "";
    count = 1;
    meteringPoint = "";
    meteringSection = "";
    meteringFarm = "";

    deviceManagement = "";
    meteringSectionManagement = "";
    meteringPointManagement = "";

    constructor(
        public subNavRight: SubNavRightComponent,
        public utilityService: UtilityService,
        private sideNavService: SideNavService,
        public navigationService: NavigationService,
        private router: Router,
        private modalService: NgbModal,
        private ngxService: NgxUiLoaderService,
        private apiRequest: ApiRequestService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
    ) {

    }

    ngOnInit() {
       
        this.id = objectHash(this.sideNavItem);
        this.hierarchyExtension = [...this.hierarchy, this.id];
        if (this.sideNavItem.text === 'Dashboards') {
            this.sideNavService.setDefault(this.hierarchyExtension);
        }
        

        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        this.deviceManagement = permissions.deviceManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;


        this.collapsed = !this.sideNavService.isExpanded(this.id);
        this.subscription.add(
            this.sideNavService.expand$.subscribe((ids) => {
                const thisIDisInExpandedHierarchy = !!ids.find((id) => id === this.id);
                const sameLevel = ids.length === this.hierarchyExtension.length;

                if (!thisIDisInExpandedHierarchy && sameLevel) {
                    if (!this.collapsed) {
                        this.collapse();
                    }
                }
            })
        );

        if (this.count == 1 && this.sideNavItem.level === 'meteringFarm') {
            console.log(this.sideNavItem)
            this.collapsed = false;
            this.count++;
        }

        this.subscription.add(
            this.navigationService
                .currentURL$()
                .subscribe((currentURL) =>
                    this.determineIfActive(currentURL.replace(/#.*$/, ''), this.sideNavItem)
                )
        );

        if (this.sideNavItem.link) {
            this.sideNavService.saveCache(this.hierarchyExtension, this.sideNavItem.link);
            return this.router.navigate([this.sideNavItem.link]);
        }
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit() {
        this.afterViewInit = true;
        this.navElement = this.collapsibleSection.nativeElement;
        this.navElement.addEventListener('transitionend', (event) => {
            if (!this.collapsed) {
                this.navElement.style.height = '';
            }
        });
        if (this.isActive) {
            this.expand();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.reloadNav?.firstChange) {
            return;
        }
        this.ngOnInit();
    
        this.changeDetectorRef.detectChanges();
    
    }
    

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setActiveLevel(activeLevel: string, levelID: string, meterType: string, deviceType: string, deviceVersion:string, levelName: string) {

        localStorage.setItem("levelID", levelID);
        localStorage.setItem("activeLevel", activeLevel);
        localStorage.setItem("deviceVersion", deviceVersion);
        localStorage.setItem("meteringPointStatement", "no");

        this.activeLevel = activeLevel;
        this.deviceVersion = deviceVersion;
        if (activeLevel == "meteringFarm") {
            localStorage.removeItem("meteringSection");

            if (localStorage.getItem("meteringFarmID") != levelID) {
                localStorage.setItem("showReporting", "yes");
                localStorage.setItem("meteringFarm", levelName);
                localStorage.setItem("meteringFarmID", levelID);
            }
            this.meteringFarm = levelName;

            this.getMeteringSections();
            this.changeDetectorRef.detectChanges();
        }
        else if (activeLevel == "meteringSection") {
            localStorage.removeItem("meteringPointID");
            localStorage.setItem("showReporting", "yes");
            localStorage.setItem("meteringSectionID", levelID);
            this.meteringSection = levelName;
            this.getMeteringPoints();
            this.changeDetectorRef.detectChanges();
            if(localStorage.getItem("meteringSection") != levelName && this.navigationService._currentURL$.value.indexOf("reporting") > -1){
                localStorage.setItem("meteringSection", levelName);
                this.router.navigateByUrl("/dashboard/metering");
                setTimeout(() => {
                    this.router.navigateByUrl("/dashboard/reporting");
                }, 500)
            }
            localStorage.setItem("dateFilter", "");
            localStorage.setItem("meteringSection", levelName);

        }
        else if (activeLevel == "meteringPoint") {
            localStorage.removeItem("deviceID");

            localStorage.setItem("showReporting", "yes");
            localStorage.setItem("meteringPointID", levelID);

            localStorage.setItem("meteringPointStatement", "yes");

            this.meteringPoint = levelName;

            this.getMeteringPointMeters(levelName);
            this.changeDetectorRef.detectChanges();
            

        }
        else {
            localStorage.setItem("deviceType", deviceType);
            localStorage.setItem("meterType", meterType);
            if(meterType == "temp"){
                localStorage.setItem("unit", "°C");
            }
            localStorage.setItem("deviceID", levelID);
            localStorage.setItem("deviceVersion", deviceVersion);
            localStorage.setItem("device", levelName);

            if(localStorage.getItem("active") != "dashboard"){
                this.router.navigateByUrl("/dashboard/"+activeLevel);
                localStorage.setItem("active", "dashboard");
            }
            else{
                this.router.navigateByUrl("/dashboard/metering");
                setTimeout(() => {
                    this.router.navigateByUrl("/dashboard/" + activeLevel);
                }, 500)
            }
            
        }
        this.subNavRight.ngOnInit();
        this.changeDetectorRef.detectChanges();

    }


    getAccounts(activeLevel: string, levelID: string, pmLevel: string, levelName: string, textLevel: string) {

        localStorage.setItem("accountsLevelID", levelID);
        localStorage.setItem("activeLevel", activeLevel);
        localStorage.setItem("accountspmLevel", pmLevel);
        localStorage.setItem("activeLink", levelName + " -- " +textLevel);

        this.activeLevel = activeLevel;
        if (activeLevel == "meteringFarm") {
            localStorage.removeItem("meteringSection");
            if (localStorage.getItem("meteringFarmID") != levelID) {
                localStorage.setItem("meteringFarmID", levelID);
            }
            this.router.navigateByUrl("/dashboard/metering");
            setTimeout(() => {
                this.router.navigateByUrl("/dashboard/users");
            }, 500)
            this.changeDetectorRef.detectChanges();
        }
        else if (activeLevel == "meteringSection") {
            localStorage.removeItem("meteringPointID");
            localStorage.setItem("showReporting", "yes");
            localStorage.setItem("meteringSectionID", levelID);
            this.router.navigateByUrl("/dashboard/metering");
            setTimeout(() => {
                this.router.navigateByUrl("/dashboard/users");
            }, 500)
            this.changeDetectorRef.detectChanges();
        }
        else if (activeLevel == "meteringPoint") {
            localStorage.removeItem("deviceID");
            localStorage.setItem("showReporting", "yes");
            localStorage.setItem("meteringPointID", levelID);
            this.router.navigateByUrl("/dashboard/metering");
            setTimeout(() => {
                this.router.navigateByUrl("/dashboard/users");
            }, 500)
            this.changeDetectorRef.detectChanges();

        }
        this.changeDetectorRef.detectChanges();

    }


    getMeteringSections() {

        let body = {
            "payload": {
                "meteringFarmID": parseInt(localStorage.getItem("meteringFarmID") as string),
                "platform": "web"
            }
        }
        this.apiRequest.apiRequest("metering/metering_sections_list/", "metering_sections_list.json", body)
            .subscribe(res => {
                if (res.data.meteringSections && res.data.meteringSections.length < 1) {

                    this.modalService.open(this.noMeteringSections).result.then(
                        (result) => {
                            if (result === 'addMeteringSection') {
                                localStorage.setItem("meteringSectionAction", "add_metering_section");
                                this.router.navigateByUrl("/dashboard/metering/meteringSection");
                                //window.location.assign("/dashboard/metering/meteringSection")
                            }
                        },
                        (reason) => { }
                    );


                }

                this.changeDetectorRef.detectChanges();

            });
    }


    getMeteringPoints() {

        let body = {
            "payload": {
                "meteringSectionID": parseInt(localStorage.getItem("meteringSectionID") as string),
                "platform": "web"
            }
        }
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        this.apiRequest.apiRequest("metering/metering_points_list/", "metering_points_list.json", body)
            .subscribe(res => {
                if (res.data.meteringPoints && res.data.meteringPoints.length < 1) {

                    this.modalService.open(this.noMeteringPoints).result.then(
                        (result) => {
                            
                            if (result === 'add') {
                                this.router.navigateByUrl("/dashboard/metering");
                                localStorage.setItem("meteringPointAction", "add_metering_point");
                                setTimeout(() =>{
                                    this.router.navigateByUrl("/dashboard/metering/meteringPoint");
                                },500);
                                //window.location.assign("/dashboard/metering/meteringPoint")
                            }
                            if (result === 'editMeteringSection') {
                                if(this.meteringSectionManagement == "viewer" || this.meteringSectionManagement == "no-access") {
                                    let activeLevel = "Metering Section " +localStorage.getItem("meteringSection") as string;
                                    this.toastService.show("Edit Warning", "You do not have permissions to edit \n" + activeLevel , { headerClasses: 'bg-warning text-light', ...defaultOptions });
                                }
                                else{
                                    this.router.navigateByUrl("/dashboard/metering");
                                    localStorage.setItem("meteringSectionAction", "update_metering_section");
                                    setTimeout(() =>{
                                        this.router.navigateByUrl("/dashboard/metering/meteringSection");
                                    },500);
                                    //window.location.assign("/dashboard/metering/meteringSection")
                                }

                              
                            }
                        },
                        (reason) => { }
                    );


                }

                this.changeDetectorRef.detectChanges();

            });
    }

    viewMeteringPointStatement(activeLevel: string, levelID: string, levelName: string) {
        localStorage.setItem("activeLevel", activeLevel);
        localStorage.setItem("meteringPointStatement", "no");
        localStorage.setItem("statement", levelID);
        localStorage.setItem("credit", levelID);
        localStorage.setItem("meteringPointID", levelID);

        localStorage.removeItem("deviceID");
        localStorage.removeItem("device");


        localStorage.setItem("showReporting", "yes");
        localStorage.setItem("meteringPointStatement", "yes");

        this.meteringPoint = levelName;
        this.activeLevel = activeLevel;

        this.changeDetectorRef.detectChanges();

        //if(localStorage.getItem("active") == "dashboard"){
            if(localStorage.getItem("meteringPoint") != levelName){
                this.router.navigateByUrl("/dashboard");
            }
            localStorage.setItem("meteringPoint", levelName);

            setTimeout(() => {
                this.router.navigateByUrl("/dashboard/meteringPoint");
            }, 500)
        //}
        this.subNavRight.ngOnInit();

        this.changeDetectorRef.detectChanges();
    }


    viewCreditProfile(activeLevel: string, levelID: string, levelName: string) {
        localStorage.setItem("activeLevel", activeLevel);
        localStorage.setItem("meteringPointStatement", "no");
        localStorage.setItem("statement", levelID);
        localStorage.setItem("credit", levelID);
        localStorage.setItem("meteringPointID", levelID);

        localStorage.removeItem("deviceID");
        localStorage.removeItem("device");


        localStorage.setItem("showReporting", "yes");
        localStorage.setItem("meteringPointStatement", "yes");

        this.meteringPoint = levelName;
        this.activeLevel = activeLevel;

        this.changeDetectorRef.detectChanges();

        //if(localStorage.getItem("active") == "dashboard"){
            if(localStorage.getItem("meteringPoint") != levelName){
                this.router.navigateByUrl("/dashboard");
            }
            localStorage.setItem("meteringPoint", levelName);

            setTimeout(() => {
                this.router.navigateByUrl("/dashboard/creditProfile");
            }, 500)
        //}
        this.subNavRight.ngOnInit();

        this.changeDetectorRef.detectChanges();
    }

    viewModems(activeLevel: string, levelID: string, levelName: string) {
        localStorage.setItem("activeLevel", activeLevel);
        localStorage.setItem("meteringPointStatement", "no");
        localStorage.setItem("modems", levelID);
        localStorage.removeItem("meteringPointID");
        localStorage.setItem("showReporting", "yes");
        localStorage.setItem("meteringSectionID", levelID);
        localStorage.removeItem("deviceID");
        localStorage.removeItem("device");
        localStorage.setItem("meteringPointStatement", "no");

        this.activeLevel = activeLevel;

        this.changeDetectorRef.detectChanges();

        this.router.navigateByUrl("/dashboard");

        setTimeout(() => {
            this.router.navigateByUrl("/dashboard/metering/modems");
        }, 500)
        this.subNavRight.ngOnInit();

        this.changeDetectorRef.detectChanges();
    }


    getMeteringPointMeters(levelName: any) {
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };

        let body = {
            "payload": {
                "meteringPointID": parseInt(localStorage.getItem("meteringPointID") as string),
                "platform": "web"
            }
        }
        this.apiRequest.apiRequest("metering/metering_point_meters_list/", "metering_point_meters_list.json", body)
            .subscribe(res => {
                if (res.data.meters && res.data.meters.length < 1) {

                    this.modalService.open(this.noDevice).result.then(
                        (result) => {
                            if (result === 'add') {
                                this.router.navigateByUrl("/dashboard/metering");

                                localStorage.setItem("deviceAction", "add");
                                setTimeout(() =>{
                                    this.router.navigateByUrl("/dashboard/metering/deviceSelect");
                                },500);
                            }
                            if (result === 'editMeteringPoint') {
                                if(this.meteringPointManagement == "viewer" || this.meteringPointManagement == "no-access") {
                                    let activeLevel = "Metering Point " +localStorage.getItem("meteringPoint") as string;
                                    this.toastService.show("Edit Warning", "You do not have permissions to edit \n" + activeLevel , { headerClasses: 'bg-warning text-light', ...defaultOptions });
                                }
                                else{
                                    this.router.navigateByUrl("/dashboard/metering");
                                    localStorage.setItem("meteringPointAction", "update_metering_point");
                                    setTimeout(() =>{
                                        this.router.navigateByUrl("/dashboard/metering/meteringPoint");
                                    },500);
                                    //window.location.assign("/dashboard/metering/meteringPoint")
                                }
                                
                            }
                        },
                        (reason) => { }
                    );


                }
            
                this.changeDetectorRef.detectChanges();

            });
    }

    determineIfActive(url: string, sideNavItem: SideNavItem) {
        // this.isActive = false;
        
        if (sideNavItem.link) {
            this.isActive = url === sideNavItem.link;
            this.changeDetectorRef.markForCheck();
        }
        
        if (sideNavItem.submenu) {
            sideNavItem.submenu.forEach((submenu) => this.determineIfActiveParent(url, submenu));
        }
        else{
            if(localStorage.getItem("device")){
                this.isActive = localStorage.getItem("device") === sideNavItem.text;
                this.changeDetectorRef.markForCheck();
            }
            else if(localStorage.getItem("statement")){
                this.isActive = localStorage.getItem("statement") === sideNavItem.key?.toString();
                this.changeDetectorRef.markForCheck();
            }
            else if(localStorage.getItem("credit")){
                this.isActive = localStorage.getItem("credit") === sideNavItem.key?.toString();
                this.changeDetectorRef.markForCheck();
            }
            else if(localStorage.getItem("modems")){
                this.isActive = localStorage.getItem("modems") === sideNavItem.key?.toString();
                this.changeDetectorRef.markForCheck();
            }
        }
       
        this.changeDetectorRef.markForCheck();
    }

    determineIfActiveParent(url: string, sideNavItem: SideNavItem) {
        if (sideNavItem.link && url === sideNavItem.link) {
            this.isActive = true;
            if (this.afterViewInit) {
                this.expand();
            }
            this.changeDetectorRef.markForCheck();
        }
        if (sideNavItem.submenu) {
            sideNavItem.submenu.forEach((submenu) => this.determineIfActiveParent(url, submenu));
        }
    }

    toggle(activeLevel: string, levelName: string, userAccount: string) {
        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };
        
        localStorage.setItem("activeLevel", activeLevel);

        if (activeLevel == "meteringFarm" && userAccount != "yes") {
            localStorage.setItem("meteringFarm", levelName);
        }
        else if (activeLevel == "meteringSection" && userAccount != "yes") {
            
            localStorage.setItem("meteringSection", levelName);

        }
        else if (activeLevel == "meteringPoint" && userAccount != "yes") {
            setTimeout(()=>{
                localStorage.setItem("meteringPoint", levelName);
            },2000);
        }

        if (this.sideNavItem.link) {
            this.sideNavService.saveCache(this.hierarchyExtension, this.sideNavItem.link);
            return this.router.navigate([this.sideNavItem.link]);
        }
        if (this.collapsed) {

            if (this.sideNavItem.submenu != null && this.sideNavItem.submenu.length < 1) {

                console.log(this.sideNavItem.level);

                if (this.sideNavItem.level == "meteringFarm") {
                    this.apiRequest.apiRequest('metering/metering_sections_list/', 'metering_sections_list.json', {
                        "payload": {
                            "meteringFarmID": this.sideNavItem.key
                        }
                    }).subscribe(data => {
                        if (data.success == true) {
                            this.sideNavItem.submenu = [];
                            if (data.data.meteringSections.length > 0 && this.meteringSectionManagement != "no-access") {

                                data.data.meteringSections.forEach((element: any, index: any, array: any[]) => {
                                    //Add for each metering section 
                                    let dataitem1: SideNavItem = {
                                        type: 'modems',
                                        icon: '',
                                        userAccount: "yes",
                                        imgSrc: 'assets/dashboard/menu/modem.png',
                                        text: "Modems",
                                        submenu: [],
                                        key: element.meteringSectionID,
                                        level: "modems"
                                    };
                                    this.sideNavItem.submenu?.push(dataitem1);

                                    let dataitem: SideNavItem = {
                                        icon: '',
                                        imgSrc: 'assets/dashboard/menu/building.svg',
                                        text: element.meteringSectionName,
                                        submenu: [],
                                        key: element.meteringSectionID,
                                        level: "meteringSection"

                                    };
                                    this.sideNavItem.submenu?.push(dataitem);
                                    this.changeDetectorRef.detectChanges();
                                    if (index === array.length - 1) {
                                        this.changeDetectorRef.markForCheck();
                                        return this.expand();
                                    }
                                    index = index + 1;
                                });
                            }
                            else {
                                this.toastService.show("No Metering Sections Found", "You do not have any Metering Sections \n", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                            }

                        } else {
                            console.log('request failed');
                        }
                    }
                    );
                } else if (this.sideNavItem.level == "meteringSection") {
                    this.apiRequest.apiRequest('metering/metering_points_list/', 'metering_points_list.json', {
                        "payload": {
                            "meteringSectionID": this.sideNavItem.key
                        }
                    }).subscribe(data => {
                        if (data.success == true) {
                            this.sideNavItem.submenu = [];
                            if (data.data.meteringPoints && this.meteringPointManagement != "no-access") {

                                data.data.meteringPoints.forEach((element: any, index: any, array: any[]) => {
                                    let dataitem: SideNavItem = {
                                        icon: '',
                                        imgSrc: 'assets/dashboard/menu/vertical-building.svg',
                                        text: element.meteringPointName,
                                        totalMeters: element.totalMeters,
                                        submenu: [],
                                        key: element.meteringPointID,
                                        level: "meteringPoint"

                                    };
                                    this.sideNavItem.submenu?.push(dataitem);
                                    this.changeDetectorRef.detectChanges();
                                    if (index === array.length - 1) {
                                        this.changeDetectorRef.markForCheck();
                                        return this.expand();
                                    }
                                    index = index + 1;
                                });
                            }
                            else {
                                this.toastService.show("No Metering Points Found", "You do not have any Metering Points \n Please contact your admin", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                            }
                        }
                    });
                } else if (this.sideNavItem.level == "meteringPoint") {
                    console.log("nim")
                    this.apiRequest.apiRequest('metering/metering_point_meters_list/', 'metering_point_meters_list.json', {
                        "payload": {
                            "meteringPointID": this.sideNavItem.key
                        }
                    }).subscribe(data => {
                        if (data.success == true) {
                            this.sideNavItem.submenu = [];
                            if (data.data.meters && this.deviceManagement != "no-access") {
                               //only display for where there is meters
                                let dataitem: any = [ 
                                    {
                                        type: 'statement',
                                        icon: '',
                                        imgSrc: 'assets/dashboard/menu/doc.png',
                                        text: "View Statement",
                                        key: this.sideNavItem.key,
                                        level: "statement"
                                    },
                                ];
                                this.sideNavItem.submenu?.push(dataitem);
                                this.changeDetectorRef.detectChanges();

                                data.data.meters.forEach((element: any, index: any, array: any[]) => {

                                    let ext = '.svg';
                                    if (element.meterType != 'water' && element.meterType != 'electricity' && element.meterType != 'gas') ext = '.png';
                                    let dataitem: any = {
                                        icon: '',
                                        imgSrc: 'assets/dashboard/menu/' + element.meterType + ext,
                                        text: element.deviceName,
                                        submenu: null,
                                        key: element.deviceID,
                                        meterType: element.meterType,
                                        deviceVersion: element.deviceVersion,
                                        level: "device"
                                    };
                                    this.sideNavItem.submenu?.push(dataitem);
                                    this.changeDetectorRef.detectChanges();
                                    if (index === array.length - 1) {
                                        this.changeDetectorRef.markForCheck();
                                        return this.expand();
                                    }
                                    index = index + 1;
                                });

                               
                            }
                            else {
                                this.toastService.show("No Devices Found", "You do not have any Devices \n Please contact your admin", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                            }
                        }
                    });
                }

            } else {
                return this.expand();
            }


        } else {
            return this.collapse();
        }

    }


    toggleAccount(activeLevel: string, levelName: string, userAccount: string) {
        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };

        localStorage.setItem("activeLevel", activeLevel);

        if (activeLevel == "meteringFarm" && userAccount != "yes") {
            localStorage.setItem("meteringFarm", levelName);
        }
        else if (activeLevel == "meteringSection" && userAccount != "yes") {
            
            localStorage.setItem("meteringSection", levelName);

        }
        else if (activeLevel == "meteringPoint" && userAccount != "yes") {
            localStorage.setItem("meteringPoint", levelName);
        }

        if (this.sideNavItem.link) {
            this.sideNavService.saveCache(this.hierarchyExtension, this.sideNavItem.link);
            return this.router.navigate([this.sideNavItem.link]);
        }
        if (this.collapsed) {

            if (this.sideNavItem.submenu != null && this.sideNavItem.submenu.length < 1) {
                if (this.sideNavItem.level == "meteringFarm") {
                    
                } else if (this.sideNavItem.level == "meteringSection") {
                    
                   
                }
                else if (this.sideNavItem.level == "meteringPoint") {

                    this.apiRequest.apiRequest('metering/metering_point_meters_list/', 'metering_point_meters_list.json', {
                        "payload": {
                            "meteringPointID": this.sideNavItem.key
                        }
                    }).subscribe(data => {
                        if (data.success == true && !this.sideNavItem.userAccount) {
                            this.sideNavItem.submenu = [];

                            let allUsers: SideNavItem = {
                                type: 'account',
                                icon: '',
                                userAccount: "yes",
                                pmLevel: "all",
                                imgSrc: 'assets/dashboard/menu/userAll.png',
                                text: "All Accounts",
                                textLevel: this.sideNavItem.text,
                                submenu: [],
                                key: this.sideNavItem.key,
                                level: "meteringPoint"

                            };
                            this.sideNavItem.submenu?.push(allUsers);


                            let editorUsers: SideNavItem = {
                                type: 'account',
                                icon: '',
                                userAccount: "yes",
                                pmLevel: "3",
                                imgSrc: 'assets/dashboard/menu/userEditor.png',
                                text: "Editors Only",
                                textLevel: this.sideNavItem.text,
                                submenu: [],
                                key: this.sideNavItem.key,
                                level: "meteringPoint"

                            };
                            this.sideNavItem.submenu?.push(editorUsers);

                            let viewerUsers: SideNavItem = {
                                type: 'account',
                                icon: '',
                                userAccount: "yes",
                                pmLevel: "4",
                                imgSrc: 'assets/dashboard/menu/userViewer.png',
                                text: "Viewers Only",
                                textLevel: this.sideNavItem.text,
                                submenu: [],
                                key: this.sideNavItem.key,
                                level: "meteringPoint"

                            };
                            this.sideNavItem.submenu?.push(viewerUsers);

                            this.changeDetectorRef.detectChanges();
                            this.changeDetectorRef.markForCheck();
                            return this.expand();
                            
                        }
                    });
                }

            } else {
                return this.expand();
            }


        } else {
            return this.collapse();
        }

    }

    collapse() {
        this.isActive = false;
        this.sideNavService.setExpanded(this.id, false);
        const navHeight = this.navElement.scrollHeight;
        const elementTransition = this.navElement.style.transition;
        this.navElement.style.transition = '';
        requestAnimationFrame(() => {
            this.navElement.style.height = navHeight + 'px';
            this.navElement.style.transition = elementTransition;
            requestAnimationFrame(() => {
                this.navElement.style.height = 0 + 'px';
                this.collapsed = true;
                this.changeDetectorRef.markForCheck();
            });
        });
    }

    expand() {
        this.changeDetectorRef.detectChanges();
        this.sideNavService.setExpanded(this.id, true);
        this.navElement.style.height = this.navElement.scrollHeight + 'px';
        this.collapsed = false;
        this.isActive = true;
        this.sideNavService.expand$.next(this.hierarchyExtension);
        this.changeDetectorRef.detectChanges();

    }


}
