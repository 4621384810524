import { SideNavItems, SideNavSection } from '@modules/navigation/models';

export const sideNavSections: SideNavSection[] = [
    {
        text: 'Company',
        items: []

    },
];

export class sideNavItemsList {

    static sideNavItemsData: SideNavItems[] = [];

    public static getsideNavItem(key: number) {
        if (sideNavItemsList.sideNavItemsData.length < 1) {
            sideNavItemsList.GenerateData();
        }
        let data: any;
        data = sideNavItemsList.sideNavItemsData.find(x => x[key.toString()]);
        let dataByKey = data[key.toString()];
        return dataByKey;
    }

    public static AddData(item: SideNavItems) {
        if(localStorage.getItem("menuLoaded") && localStorage.getItem("menuIsReset") == "no") {
            sideNavItemsList.sideNavItemsData = [];
            localStorage.setItem("menuIsReset", "yes")
        }
        sideNavItemsList.sideNavItemsData.push(item);
    }
    public static GenerateData() {
        sideNavItemsList.sideNavItemsData = [
            {
                ["1"]: {
                    icon: 'activity',
                    submenu: [

                    ],
                    text: 'Dashboards',
                    key: 1
                }
            }
        ];

        sideNavItemsList.sideNavItemsData.forEach(item => {
            sideNavItemsList.sideNavItemsData.push(item);
        });
    }
    constructor() {
        console.log("--------------Data Added-------------------");
        console.log(sideNavItemsList.sideNavItemsData);
    }


};