import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DashboardHeadCompactComponent } from './dashboard-head-compact/dashboard-head-compact.component';
import { DashboardHeadComponent } from './dashboard-head/dashboard-head.component';

import { DashboardSubHeadComponent } from './dashboard-sub-head/dashboard-sub-head.component';
import { SideNavItemProComponent } from './side-nav-item-pro/side-nav-item-pro.component';
import { TopNavAlertsItemComponent } from './top-nav-alerts-item/top-nav-alerts-item.component';
import { TopNavAlertsComponent } from './top-nav-alerts/top-nav-alerts.component';
import { TopNavDocsComponent } from './top-nav-docs/top-nav-docs.component';
import { TopNavSearchComponent } from './top-nav-search/top-nav-search.component';
import { TopNavUserComponent } from './top-nav-user/top-nav-user.component';

import { SubNavRightComponent } from './sub-nav-right/sub-nav-right.component';

export const components = [
    SideNavItemProComponent,
    BreadcrumbsComponent,
    DashboardHeadComponent,
    DashboardSubHeadComponent,
    TopNavUserComponent,
    TopNavAlertsComponent,
    TopNavSearchComponent,
    TopNavAlertsItemComponent,
    TopNavDocsComponent,
    DashboardHeadCompactComponent,
    SubNavRightComponent,
];

export * from './side-nav-item-pro/side-nav-item-pro.component';
export * from './breadcrumbs/breadcrumbs.component';

export * from './dashboard-head/dashboard-head.component';
export * from './dashboard-head-compact/dashboard-head-compact.component';

export * from './sub-nav-right/sub-nav-right.component';

export * from './dashboard-sub-head/dashboard-sub-head.component';

export * from './top-nav-user/top-nav-user.component';
export * from './top-nav-alerts/top-nav-alerts.component';
export * from './top-nav-search/top-nav-search.component';
export * from './top-nav-alerts-item/top-nav-alerts-item.component';
export * from './top-nav-docs/top-nav-docs.component';
