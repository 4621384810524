import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    @Input() rtl = false;
    @Input() impersination!: string;

    company: any = "Smart-View Utilities";
    constructor(private navigationService: NavigationService) {}
    ngOnInit() {

        if(localStorage.getItem("companyName") && localStorage.getItem("companyName") != "" && localStorage.getItem("companyName") != null && localStorage.getItem("companyName") != "null"){
            this.company = localStorage.getItem("companyName");
        }
    }
    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }
}
